import { gql } from 'graphql-tag';

export default gql`
    fragment ImageSummaryFragment on Image {
        url
        altText
        caption
        width
        height
    }
`;
